import React, { useState, useEffect } from "react";
import { ResponsiveContainer, Legend, Pie, Cell, PieChart } from "recharts";

//const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

//const COLORS = ["#D3D3D3", "#A9A9A9", "#808080", "#696969", "#4F4F4F"];

const COLORS = ["#FFCCCB", "#FFFACD", "#E6E6FA", "#D3FFCE", "#FFDAB9"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      style={{ fontFamily: "InterRegular" }}
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function Main(props) {
  const [data, setData] = useState(props.data ? props.data : []);
  useEffect(() => {
    setData(props.data ? props.data : []);
  }, [props.data]);

  return (
    <ResponsiveContainer width={"100%"} height="80%">
      <PieChart width={730} height={250}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          style={{ fontFamily: "InterRegular" }}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data &&
            data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
        </Pie>
        <Legend wrapperStyle={{ fontFamily: "InterRegular" }} />
      </PieChart>
    </ResponsiveContainer>
  );
}
