import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";

import { useDispatch, useSelector } from "react-redux";

import { uri } from "../../../constants/settings";

import AddIcon from "@mui/icons-material/Add";

const base64 = require("base-64");

const styles = {
  input: {
    display: "none",
  },
};

export default function Main(props) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({ photo: "", preview: "", upload: 0 });
  const user = "";
  const pass = "";

  //const user = store.getState().user.slot.wysi;
  //const pass = store.getState().user.slot.wyg;
  const credentials = user + ":" + pass;

  useEffect(() => {
    fetchPhoto();
    //console.log(form)
  }, []);

  async function fetchPhoto() {
    const user = "mojeed.oyedeji@gmail.com";
    const pass = "omopeyemi";
    const credentials = user + ":" + pass;
    var url = "";
    if (uri.mode == "test") {
      url = uri.test + "/realtor/images/fetchAvatar/";
    } else if (uri.mode == "live") {
      url = uri.live + "/realtor/images/fetchAvatar/";
    } else {
      url = uri.dev + "/realtor/images/fetchAvatar/";
    }

    await fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-type": "application/json",
        Authorization: "Basic " + base64.encode(credentials),
      },
      body: JSON.stringify({
        uri: props.uri,
        type: "avatar",
      }),
    })
      .then((response) => response.blob())
      .then((res) => {
        if (res.size > 100) {
          setForm({
            photo: res,
            preview: URL.createObjectURL(res),
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Avatar style={{ width: 70, height: 70 }} src={form.preview} />
    </div>
  );
}
