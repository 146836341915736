import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import "react-alice-carousel/lib/alice-carousel.css";

import VillaIcon from "@mui/icons-material/Villa";
import ReviewsIcon from "@mui/icons-material/Reviews";
import LinkIcon from "@mui/icons-material/Link";
import AnalyticsIcon from "@mui/icons-material/Analytics";

import { page } from "../../styles/main";
import { Typography, requirePropFactory } from "@mui/material";
import { getListings } from "../../actions/listings";

import Profile from "../../components/web/realtor/Profile";
import Reviews from "../../components/web/reviews/Review";
import Linkings from "../../components/web/linkings/Linkings";
import Listings from "../../components/web/listings/Listings";
import Summary from "../../components/web/summary/Main";

import { getRealtor } from "../../actions/realtors";

const classes = {
  root: {
    marginTop: 10,
  },
};

const styles = {
  media: {
    height: "100%",
    paddingTop: "75%", // 16:9,
    marginTop: "30",
  },
};

export default function Main() {
  const history = useHistory();
  const dispatch = useDispatch();
  const listings = useSelector((state) => state.listing.listings);
  const realtor = useSelector((state) => state.realtor.realtor);
  const [data, setData] = useState(listings ? listings : []);
  const [tab, setTab] = useState(0);

  let { id } = useParams();

  useEffect(() => {
    const body = { url: id };
    dispatch(getRealtor(body));
    document.title = "Rentage | " + realtor.name;
  }, []);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div>
      <Grid
        style={{ height: "100vh" }}
        justifyContent="center"
        container
        direction="row"
      >
        <Grid
          item
          xs={12}
          lg={10}
          sm={12}
          md={10}
          style={{ marginTop: 0, padding: 10 }}
        >
          <div style={{ marginTop: 10 }} />

          <div style={{ marginTop: 30 }} />

          <div style={{ marginTop: 10 }} />
          <Grid container direction="row" spacing={1} justifyContent={"center"}>
            <Grid item md={10} xs={12} sm={12}>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <img
                  style={{ width: 150 }}
                  src={require("../../images/logo/1x/Asset 1.png")}
                />
              </div>
              <div style={{ marginTop: 20 }} />
              <Profile />

              <Tabs
                value={tab}
                onChange={handleChange}
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  icon={<AnalyticsIcon />}
                  iconPosition="start"
                  label={
                    <Typography
                      variant="body1"
                      style={{ fontFamily: "InterBold" }}
                    >
                      Summary
                    </Typography>
                  }
                />
                <Tab
                  icon={<VillaIcon />}
                  iconPosition="start"
                  label={
                    <Typography
                      variant="body1"
                      style={{ fontFamily: "InterBold" }}
                    >
                      Listings
                    </Typography>
                  }
                />
                <Tab
                  icon={<LinkIcon />}
                  iconPosition="start"
                  label={
                    <Typography
                      variant="body1"
                      style={{ fontFamily: "InterBold" }}
                    >
                      Links
                    </Typography>
                  }
                />
                <Tab
                  icon={<ReviewsIcon />}
                  iconPosition="start"
                  label={
                    <Typography
                      variant="body1"
                      style={{ fontFamily: "InterBold" }}
                    >
                      Reviews
                    </Typography>
                  }
                />
              </Tabs>

              <div style={{ marginTop: 20 }} />
              {tab == 0 && <Summary />}
              {tab == 1 && <Listings />}
              {tab == 2 && <Linkings />}
              {tab == 3 && <Reviews />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
