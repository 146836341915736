import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { fetchRealtor } from "../../../actions/realtors";

import ImageAvatar from "./Avatar";

export default function Main(props) {
  const listings = useSelector((state) => state.listing.listings);
  const reviews = useSelector((state) => state.review.reviews);

  const dispatch = useDispatch();
  const history = useHistory();
  const realtor = props.data;

  function handleClick() {
    dispatch(fetchRealtor(props.data));
    history.push("/" + props.data.url);
  }

  return (
    <Card elevation={0} button sx={{}}>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Grid container direction="row" spacing={1}>
            <Grid item md={3}>
              <ImageAvatar uri={realtor.avatar} />
            </Grid>
            <Grid item md={9}>
              <Typography variant="body1" style={{ fontFamily: "InterBold" }}>
                {realtor.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {realtor.email}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {listings.filter((item) => item.realtor == realtor.id).length}{" "}
                listings |{" "}
                {reviews.filter((item) => item.entity == realtor.id).length}{" "}
                Reviews
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
