import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { fetchListing } from "../../../actions/listings";
import ImageAvatar from "./Avatar";

export default function GridListing(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const listing = props.data;

  function handleClick() {
    dispatch(fetchListing(props.data));
    history.push("/listing/" + props.data.url);
  }

  return (
    <Card elevation={0}>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item xs={2}>
              <ImageAvatar uri={listing.images.split(",")[0]} />
            </Grid>
            <Grid item xs={9}>
              <Typography
                style={{ fontFamily: "InterBold" }}
                variant="body1"
                noWrap
              >
                {listing.title}
              </Typography>

              <Typography variant="body2">{listing.price} NGN</Typography>
              <Typography variant="body2" noWrap color="text.secondary">
                {listing.address}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
