import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Rating from "@mui/material/Rating";
import { ResponsiveContainer, Legend, Pie, Cell, PieChart } from "recharts";

import AddReview from "./AddReview";
import Chart from "./Chart";

import { getRealtorReviews } from "../../../actions/reviews";
import { ratingDistribution, averageRating } from "../../../functions/chart";

const ratings = ["excellent", "good", "average", "fair", "poor"];
export default function Main() {
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const realtor = useSelector((state) => state.realtor.realtor);
  const reviews = useSelector((state) => state.review.reviews);
  const pieData = ratingDistribution(reviews);
  const [data, setData] = useState(reviews ? reviews : []);

  useEffect(() => {
    const body = { id: realtor.id };
    dispatch(getRealtorReviews(body));
  }, []);

  useEffect(() => {
    setData(data ? data : []);
  }, [reviews]);

  function addReview() {
    setAdd(true);
  }

  return (
    <div>
      <AddReview open={add} close={() => setAdd(false)} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          variant="h6"
          color="textSecondary"
          style={{ fontFamily: "InterBold" }}
        >
          OVERALL RATING
        </Typography>
        <Typography
          align="center"
          variant="h4"
          style={{ fontFamily: "SpaceBold" }}
        >
          {averageRating(pieData)}
        </Typography>

        <div style={{ height: 300, width: "100%" }}>
          <Chart data={pieData} />
        </div>
        <Button variant="outlined" onClick={() => addReview()} color="primary">
          <Typography style={{ fontFamily: "InterBold" }} variant="body1">
            {" "}
            Add Review
          </Typography>
        </Button>
      </div>

      <Grid container direction="row">
        <Grid item md={12}>
          <div style={{ marginTop: 20 }} />

          <Grid container direction="row" spacing={3}>
            {data ? (
              data.map((item) => (
                <Grid item md={4}>
                  <ReviewItem data={item} />
                </Grid>
              ))
            ) : (
              <Typography variant="h6" color="textSecondary">
                No Reviews
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
    </div>
  );
}

function ReviewItem(props) {
  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar style={{ width: 50, height: 50 }} />
        <Typography variant="body1" style={{ fontFamily: "InterBold" }}>
          {" "}
          {props.data.reviewer_name}
        </Typography>
        <Rating
          name="read-only"
          value={ratings.reverse().indexOf(props.data.rating) + 1}
          readOnly
        />
      </div>

      <div style={{ marginTop: 5 }} />
      <Typography
        sx={{
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
        }}
        variant="body2"
        align="justify"
      >
        {props.data.review}
      </Typography>

      <div style={{ marginTop: 5 }} />
      <Typography color="textSecondary" variant="body2" align="justify">
        {" "}
        {formatDate(props.data.created)} | {formatTime(props.data.created)}{" "}
      </Typography>

      <div style={{ marginTop: 5 }} />
    </div>
  );
}

function formatDate(dateStr) {
  const date = new Date(dateStr);

  // Define options for formatting the date
  const options = { year: "numeric", month: "short", day: "numeric" };

  // Format the date to "Jan 4, 2023"
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
  console.log(formattedDate);
}

function formatTime(dateStr) {
  const date = new Date();

  const options = { hour: "2-digit", minute: "2-digit", hour12: true };

  // Extract the time in the format "HH:MM:SS AM/PM"
  const time = date.toLocaleTimeString("en-US", options);

  return time;
}
