import React, { useState, useEffect, createContext, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { addRequest } from "../../../actions/requests";
import { findUser, createUser } from "../../../actions/user";

const styles = {
  dialogPaper: {
    minHeight: "50vh",
    maxHeight: "50vh",
  },
};

const ScreenContext = createContext();

export default function Main(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(props.open);
  const user = useSelector((state) => state.user.user);
  const [screen, setScreen] = useState(user.id != null ? "review" : "login");
  const realtor = useSelector((state) => state.realtor.realtor);
  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState({
    realtor: realtor.id,
  });

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (form.auth != user.auth && screen == "auth") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [form.auth]);

  function handleClose() {
    props.close();
  }

  function handleReview() {
    dispatch(addRequest(form));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    const newForm = { ...form, [name]: value };
    setForm(newForm);
    //dispatch(updateForm(newForm));
  }

  function handleAuth() {
    if (form.auth == user.auth) {
      setDisabled(false);
      setScreen("review");
    }
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          minHeight: 450,
        },
      }}
      classes={{ paper: styles.dialogPaper }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography variant="h4" style={{ fontFamily: "InterBold" }}>
          Service Form
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ justifyContent: "center", alignItems: "center" }}>
        <TextField
          name="name"
          onChange={handleChange}
          fullWidth
          type="text"
          label="Your Name"
          placeholder="Your Name"
          variant="standard"
        />
        <div style={{ marginTop: 10 }} />
        <TextField
          name="email"
          onChange={handleChange}
          fullWidth
          type="email"
          label="Email Address"
          placeholder="Email Address"
          variant="standard"
        />
        <div style={{ marginTop: 10 }} />
        <TextField
          name="phone"
          onChange={handleChange}
          fullWidth
          type="phone"
          label="Phone Number"
          placeholder="Phone Number"
          variant="standard"
        />
        <div style={{ marginTop: 10 }} />
        <FormControl>
          <FormLabel id="value">
            <Typography variant="body1">I need </Typography>
          </FormLabel>
          <RadioGroup
            defaultValue="rating"
            row
            onChange={handleChange}
            name="category"
          >
            <FormControlLabel
              value="rental"
              control={<Radio />}
              label="Rentals"
            />
            <FormControlLabel
              value="sale"
              control={<Radio />}
              label="Property for Sale"
            />

            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>

        <div style={{ marginTop: 20 }} />
        <TextField
          multiline
          variant="standard"
          rows={5}
          name="description"
          onChange={handleChange}
          label="Please provide some details"
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          <Typography variant="body1" fontFamily="SpaceBold">
            Cancel
          </Typography>
        </Button>
        <Button disabled={disabled} onClick={handleReview}>
          <Typography variant="body1" style={{ fontFamily: "SpaceBold" }}>
            Continue
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
