import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import "react-alice-carousel/lib/alice-carousel.css";

import VillaIcon from "@mui/icons-material/Villa";
import ReviewsIcon from "@mui/icons-material/Reviews";
import LinkIcon from "@mui/icons-material/Link";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import { page } from "../../../styles/main";

import CardListing from "./CardListing";
import GridListing from "./GridListing";
import { getRealtor } from "../../../actions/realtors";
import { getRealtorListings } from "../../../actions/listings";

export default function Main(props) {
  const dispatch = useDispatch();
  const listings = useSelector((state) => state.listing.listings);
  const [data, setData] = useState(listings ? listings : []);
  const realtor = useSelector((state) => state.realtor.realtor);

  const [alignment, setAlignment] = React.useState("detailed");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    const body = { id: realtor.id };
    dispatch(getRealtorListings(body));
  }, [realtor]);

  useEffect(() => {
    setData(listings ? listings : []);
  }, [listings]);

  function handleFilter(searchText, location) {
    if (location == "all") {
      setData(
        listings
          .filter((item) => item.state != location)
          .filter((item) => item.title.includes(searchText))
      );
    } else {
      setData(
        listings
          .filter((item) => item.state == location)
          .filter((item) => item.title.includes(searchText))
      );
    }
  }
  return (
    <>
      <Search update={(search, location) => handleFilter(search, location)} />
      <div style={{ marginTop: 20 }} />

      <div
        style={{
          marginTop: 20,
          marginBottom: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton size="small" value="simple">
            <ViewListIcon />
          </ToggleButton>
          <ToggleButton size="small" value="detailed">
            <GridViewIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <Grid container direction="row" spacing={1}>
        {data.map((item) => (
          <Grid item md={4} xs={12} sm={6}>
            {alignment == "detailed" ? (
              <CardListing data={item} />
            ) : (
              <GridListing data={item} />
            )}
          </Grid>
        ))}
      </Grid>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          justifyContent: "center",
          display: "flex",
        }}
      >
        {data.length > 9 && <Pagination count={10} shape="rounded" />}
      </div>
    </>
  );
}

function Search(props) {
  const [form, setForm] = useState({ search: "", location: "all" });

  function handleChange(event) {
    const { name, value } = event.target;
    if (name == "search") {
      props.update(value, form.location);
    } else {
      props.update(form.search, value);
    }
    setForm((form) => ({ ...form, [name]: value }));
  }

  return (
    <div>
      <Grid container direction="row" spacing={1}>
        <Grid item md={6}>
          <TextField
            required
            fullWidth
            id="filled-required"
            label="Search"
            defaultValue=""
            name="search"
            size="small"
            variant="filled"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            required
            fullWidth
            id="filled-required"
            label="Location"
            select
            size="small"
            defaultValue="all"
            name="location"
            onChange={handleChange}
            variant="filled"
          >
            <MenuItem value={"all"}> All </MenuItem>
            <MenuItem value={"lagos"}> Lagos </MenuItem>
            <MenuItem value={"abuja"}> Abuja </MenuItem>
          </TextField>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
    </div>
  );
}
