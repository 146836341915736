import {SET_USER} from  "../constants/user";


const initialState = {
  user:{
  },
};

function reducer(state = initialState, action) {
  switch (action.type){
    case SET_USER:
      return Object.assign({}, state, {
         user: action.payload
       });
  }
  return state;
}

export default reducer;
