
import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Rating from '@mui/material/Rating';

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }


export default function Main(){
    return(
        <div>


            <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
            <div style={{justifyContent:"center"}}>
            <Typography align="center" variant="h6" color="textSecondary" style={{fontFamily:"InterBold"}}>OVERALL RATING</Typography>
            <Typography align="center" variant="h4" style={{fontFamily:"SpaceBold"}}>4.0</Typography>
            </div>
            
            </div>
           

            <div style={{marginTop:10}}>
            <Typography variant="body1" color="textSecondary">Excellent</Typography>
            <LinearProgressWithLabel value={20}  />
            </div>

            <div style={{marginTop:5}}>
            <Typography variant="body1" color="textSecondary">Good</Typography>
            <LinearProgressWithLabel value={20} />
            </div>

            <div style={{marginTop:5}}>
            <Typography variant="body1" color="textSecondary">Average</Typography>
            <LinearProgressWithLabel value={20} />
            </div>

            <div style={{marginTop:5}}>
            <Typography variant="body1" color="textSecondary">Below Average</Typography>
            <LinearProgressWithLabel value={20} />
            </div>

            <div style={{marginTop:5}}>
            <Typography variant="body1" color="textSecondary">Poor</Typography>
            <LinearProgressWithLabel value={20} />
            </div>
           
            <div>
            <ReviewItem />
            <ReviewItem />
            </div>
        </div>
    )
}

function ReviewItem(){
    return(
        <div style={{marginTop:10, marginBottom:10}}>
            <Grid container direction="row" spacing={1} alignItems="flex-start">
                <Grid item md={1}>
                    <Avatar variant="rounded" style={{width:50, height:50}} />
                </Grid>
                <Grid item md={11}>
                    <Grid container direction="row" justifyContent={"space-between"}>
                        <Grid item>
                        <Typography variant="body1" style={{fontFamily:"InterBold"}}> Jane Doe </Typography>
                        </Grid>
                        <Grid item>
                        <Rating name="read-only" value={5} readOnly />
                        </Grid>
                    </Grid>
                   
                    <Typography variant="body2" align="justify"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                    when an unknown printer took a galley of type and scrambled it to make a type specimen book. </Typography>

                    <div style={{marginTop:10}} />
                    <Typography color="textSecondary" variant="body2" align="justify"> Lagos | Dec 29, 2023 | 11:03 PM </Typography>
                </Grid>
            </Grid>
            <div style={{marginTop:5}}/>
            <Divider />
        </div>
    )
}