import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import AddIcon from '@mui/icons-material/Add';

import {wait, notify, unexpectedError} from '../../../actions/index';

import {uri} from '../../../constants/settings';
import { CardActionArea } from '@mui/material';

const base64 = require('base-64');

const styles = {
    input:{
        display: 'none',
    }
}

export default function Main(props){
    const dispatch = useDispatch();
    const [form, setForm] = useState({preview:""});
    const user = "";
    const pass = "";
    const credentials = user + ":" + pass;


    useEffect( () =>  {
      //fetchPhoto();
    }, []);



   

    return(
        <CardMedia
                    component="iframe"
                    alt="green iguana"
                    height="100%"
                    image="https://www.youtube.com/embed/q-cZU7XBgBo?si=PYQngCW2GUv16uhj"
                    muted=''
                    autoPlay=''
                />
    )
}