import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";

import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { getRealtorLinks } from "../../../actions/linkings";

export default function Main() {
  const dispatch = useDispatch();
  const realtor = useSelector((state) => state.realtor.realtor);
  const links = useSelector((state) => state.linking.linkings);
  const [data, setData] = useState(links ? links : []);

  useEffect(() => {
    const body = { id: realtor.id };
    dispatch(getRealtorLinks(body));
  }, []);

  useEffect(() => {
    setData(links ? links : []);
  }, [links]);

  return (
    <div>
      <div>
        {data ? (
          data.map((item) => <LinkItem data={item} />)
        ) : (
          <Typography variant="body1">No Links yet</Typography>
        )}
      </div>
    </div>
  );
}

function LinkItem(props) {
  function handleOpen() {
    window.open(props.data.url);
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={10}>
          <Typography variant="body1" style={{ fontFamily: "InterBold" }}>
            {props.data.title}
          </Typography>

          <Typography variant="body2" color="textSecondary" align="justify">
            {props.data.description}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton color="primary" onClick={handleOpen}>
            <ArrowOutwardOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <div style={{ marginTop: 5 }} />
      <Divider />
    </div>
  );
}
