import { axiosHandler, defaultHandler } from "../requests/global.js";
import { unexpectedError, wait, notify } from "./index";
import { FETCH_LINK, FETCH_LINKS } from "../constants/linkings";

import * as validator from "../validators/request";

export function getRealtorLinks(body) {
  const url = "/page/linking/owner";
  return async (dispatch) => {
    dispatch(wait(true));
    try {
      const [res, status] = await defaultHandler(url, body);
      console.log(res);
      dispatch(fetchLinks(res ? res.response.linkings : []));
    } catch (ex) {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function fetchLinks(payload) {
  return { type: FETCH_LINKS, payload };
}

export function fetchLink(payload) {
  return { type: FETCH_LINK, payload };
}
