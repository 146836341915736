import { createTheme, } from '@mui/material/styles';

export const THEME = createTheme({
   typography: {
    "fontFamily": "\"InterRegular\", sans-serif",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "fontWeightBold": 700,
    "textTransform": "none"
  },
  palette: {
   primary: {
    main:'#000000'
   },
   background:{
    default:"#fff"
   }
 },
});
