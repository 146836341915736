import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KitchenIcon from '@mui/icons-material/Kitchen';
import BedIcon from '@mui/icons-material/Bed';
import ShowerIcon from '@mui/icons-material/Shower';
import LivingIcon from '@mui/icons-material/Living';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import GarageIcon from '@mui/icons-material/Garage';


import getSymbolFromCurrency from 'currency-symbol-map';
import AssetCardImage from '../../../components/image/portfolio/AssetCardMedia';
import ImageAvatar from './Avatar';
import {fetchListing} from '../../../actions/listings';


export default function Main(props){
    const dispatch = useDispatch();
    const history = useHistory();
    const listing = props.data

    function handleClick(){
        dispatch(fetchListing(props.data));
        history.push("/listing/" + props.data.url);
    }

    
    return(
      <Card elevation={0}>
       <CardActionArea onClick={handleClick}>
     
       <CardContent>
        <Grid container direction="row" spacing={1} justifyContent="space-between">
            <Grid item xs={2} >
            <ImageAvatar uri={listing.image1}  />
            </Grid>
            <Grid item xs={9}>
            <Typography style={{fontFamily:"InterBold"}} variant="body1" noWrap>
                {listing.title}
           </Typography>
           
           <Typography variant="body2" >{listing.price} NGN</Typography>
           <Typography variant="body2" noWrap color="text.secondary">
            {listing.address}
           </Typography>
          
            </Grid>

           
        </Grid>
      
            
       </CardContent>
       
       </CardActionArea>
       
       </Card>
    )
}