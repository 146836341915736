import React from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import InfoIcon from '@mui/icons-material/Info';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import AssetCardMedia from './VideoMedia';


const itemData = [
    {
      img: '/images/lesions/PAT_9_17_80.png',
      title: 'Captured',
      datetime: 'Mar. 18 2023, 0240',
    },
    {
      img: '/images/lesions/PAT_13_21_350.png',
      title: 'Uploaded',
      datetime: 'Mar. 18 2023, 0240',
    },
    {
      img: '/images/lesions/PAT_15_23_240.png',
      title: 'Generated',
      datetime: 'Mar. 18 2023, 0240',
    },
    {
      img: '/images/lesions/PAT_20_29_850.png',
      title: 'Captured',
      datetime: 'Mar. 18 2023, 0240',
    },
    {
      img: '/images/lesions/PAT_21_31_965.png',
      title: 'Uploaded',
      datetime: 'Mar. 18 2023, 0240',
    },
    {
      img: '/images/lesions/PAT_26_37_865.png',
      title: 'Generated',
      datetime: 'Mar. 18 2023, 0240',
    },
  ];


export default function Main(props){
    return(
        <div>
                <div style={{marginTop:10}} />

                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                    <IconButton outlined>
                        <ChevronLeftIcon color="primary" fontSize="large" />
                    </IconButton>
                    </Grid>
                    <Grid item>
                    <IconButton outlined>
                        <ChevronRightIcon color="primary" fontSize="large" />
                    </IconButton>
                    </Grid>
                </Grid>

                <div style={{marginTop:10}} />

                <ImageList sx={{ width: "100%", height: 500 }} cols={3} >
                    {itemData.map((item, index) => (
                    
                    <ImageListItem key={item.img} sx={{height:100}}>
                        <img

                        srcSet={`${item.img}?w=164&h=400&fit=crop&auto=format`}
                        src={`${item.img}?w=164&h=400&fit=crop&auto=format`}
                        alt={item.title}
                        style={{ height: 200 }}
                        loading="lazy"
                        />
                        <ImageListItemBar
                        title={item.title}
                        subtitle={item.datetime}
                        actionIcon={
                            <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label={`info about ${item.title}`}
                            >
                            <InfoIcon />
                            </IconButton>
                        }
                        />
                    </ImageListItem>
                    ))}
                </ImageList>
                           
        </div>
    )
}