export function computeTLV(listings) {
  if (listings) {
    const trv = computeTRV(listings);
    const tpv = computeTPV(listings);
    return trv + tpv;
  } else {
    return 0;
  }
}

export function computeTPV(listings) {
  if (listings) {
    const totalSalesValue = listings
      .filter((property) => property.type === "sale")
      .reduce((total, property) => total + parseFloat(property.price), 0);

    return totalSalesValue;
  } else {
    return 0;
  }
}

export function computeTRV(listings) {
  if (listings) {
    const totalRentalValue = listings
      .filter((property) => property.type === "rental")
      .reduce(
        (total, property) =>
          property.rent_period == "per-annum"
            ? total + parseFloat(property.rent)
            : total + parseFloat(property.rent) * 12,
        0
      );

    return totalRentalValue;
  } else {
    return 0;
  }
}

export function listingCategory(listings) {
  const data = [];

  const filtArr = ["rental", "sale"];

  filtArr.forEach((filt) => {
    const filteredArray = listings.filter((item) => item.type === filt);
    const entry = { name: filt, value: filteredArray.length };
    data.push(entry);
  });

  //const genderSet = new Set(data.map(item => item.gender));
  //const ge

  return data;
}

export function locationCategory(listings) {
  const data = [];
  const categoryCounts = listings.reduce((counts, property) => {
    counts[property.state] = (counts[property.state] || 0) + 1;
    return counts;
  }, {});

  const sortedCategories = Object.entries(categoryCounts).sort(
    (a, b) => b[1] - a[1]
  );
  const topThreeCategories = sortedCategories.slice(0, 3);

  const labelsArr = topThreeCategories.map((category) => category[0]);

  labelsArr.forEach((label) => {
    const filteredArray = listings.filter((item) => item.state === label);
    const entry = { name: label, value: filteredArray.length };
    data.push(entry);
  });

  const otherArray = listings.filter((item) => !labelsArr.includes(item.state));
  const otherEntry = { name: "other", value: otherArray.length };

  data.push(otherEntry);

  return data;
}

export function dailyValue(data) {
  const daysSet = new Set(
    data.map((item) => new Date(item.created).toDateString())
  );
  const dayArray = Array.from(daysSet);
  const dataSummary = [];
  dayArray.forEach((day) => {
    const saleListings = data.filter(
      (item) =>
        new Date(item.created).toDateString() ===
          new Date(day).toDateString() && item.type === "sale"
    );
    const rentalListings = data.filter(
      (item) =>
        new Date(item.created).toDateString() ===
          new Date(day).toDateString() && item.type === "rental"
    );
    const entry = {
      day: day,
      sale: computeTPV(saleListings),
      rental: computeTRV(rentalListings),
    };
    dataSummary.push(entry);
  });
  return dataSummary.sort((a, b) => new Date(a.day) - new Date(b.day));
}

export function monthlyValue(data) {
  const daysSet = new Set(
    data.map((item) => new Date(item.created).toDateString())
  );
  const dayArray = Array.from(daysSet);
  const monthYearArray = getMonthYearArray(dayArray);
  const dataSummary = [];
  monthYearArray.forEach((day) => {
    const saleListings = data.filter(
      (item) =>
        getMonthYear(new Date(item.created).toDateString()) === day &&
        item.type === "sale"
    );
    const rentalListings = data.filter(
      (item) =>
        getMonthYear(new Date(item.created).toDateString()) === day &&
        item.type === "rental"
    );
    const entry = {
      day: day,
      sale: computeTPV(saleListings),
      rental: computeTRV(rentalListings),
    };
    dataSummary.push(entry);
  });
  return dataSummary.sort((a, b) => new Date(a.day) - new Date(b.day));
}

export function yearlyValue(data) {
  const daysSet = new Set(
    data.map((item) => new Date(item.created).toDateString())
  );
  const dayArray = Array.from(daysSet);
  const yearArray = getYearArray(dayArray);
  const dataSummary = [];
  yearArray.forEach((day) => {
    const saleListings = data.filter(
      (item) =>
        new Date(item.created).getFullYear().toString() === day &&
        item.type === "sale"
    );
    const rentalListings = data.filter(
      (item) =>
        new Date(item.created).getFullYear().toString() === day &&
        item.type === "rental"
    );
    const entry = {
      day: day,
      sale: computeTPV(saleListings),
      rental: computeTRV(rentalListings),
    };
    dataSummary.push(entry);
  });
  return dataSummary.sort((a, b) => new Date(a.day) - new Date(b.day));
}

export function dailyListing(data) {
  const daysSet = new Set(
    data.map((item) => new Date(item.created).toDateString())
  );
  const dayArray = Array.from(daysSet);
  const dataSummary = [];
  dayArray.forEach((day) => {
    const saleListings = data.filter(
      (item) =>
        new Date(item.created).toDateString() ===
          new Date(day).toDateString() && item.type === "sale"
    );
    const rentalListings = data.filter(
      (item) =>
        new Date(item.created).toDateString() ===
          new Date(day).toDateString() && item.type == "rental"
    );
    const entry = {
      period: day,
      sale: saleListings.length,
      rental: rentalListings.length,
    };
    dataSummary.push(entry);
  });
  return dataSummary.sort((a, b) => new Date(a.period) - new Date(b.period));
}

export function monthlyListing(data) {
  const dataSummary = [];
  const daysSet = new Set(
    data.map((item) => new Date(item.created).toDateString())
  );

  const dayArray = Array.from(daysSet);
  const monthYearArray = getMonthYearArray(dayArray);

  monthYearArray.forEach((day) => {
    const saleListings = data.filter(
      (item) =>
        getMonthYear(new Date(item.created).toDateString()) === day &&
        item.type === "sale"
    );
    const rentalListings = data.filter(
      (item) =>
        getMonthYear(new Date(item.created).toDateString()) === day &&
        item.type == "rental"
    );
    const entry = {
      period: day,
      sale: saleListings.length,
      rental: rentalListings.length,
    };
    dataSummary.push(entry);
  });
  return dataSummary.sort((a, b) => new Date(a.period) - new Date(b.period));
}

export function yearlyListing(data) {
  const dataSummary = [];
  const daysSet = new Set(
    data.map((item) => new Date(item.created).toDateString())
  );

  const dayArray = Array.from(daysSet);
  const yearArray = getYearArray(dayArray);

  yearArray.forEach((day) => {
    const saleListings = data.filter(
      (item) =>
        new Date(item.created).getFullYear().toString() === day &&
        item.type === "sale"
    );
    const rentalListings = data.filter(
      (item) =>
        new Date(item.created).getFullYear().toString() === day &&
        item.type == "rental"
    );
    const entry = {
      period: day,
      sale: saleListings.length,
      rental: rentalListings.length,
    };
    dataSummary.push(entry);
  });
  return dataSummary.sort((a, b) => new Date(a.period) - new Date(b.period));
}

function getMonthYearArray(dates) {
  const monthYearSet = new Set();
  dates.forEach((dateString) => {
    const date = new Date(dateString);
    const monthyear = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;
    monthYearSet.add(monthyear);
  });

  return Array.from(monthYearSet).reverse();
}

function getYearArray(dates) {
  const yearSet = new Set();
  dates.forEach((dateString) => {
    const date = new Date(dateString);
    const year = `${date.getFullYear()}`;
    yearSet.add(year);
  });

  return Array.from(yearSet).reverse();
}

function getMonthYear(value) {
  const date = new Date(value);
  const monthyear = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}`;
  return monthyear;
}
