import React from 'react';
import Typography from '@mui/material/Typography';

import Features from './Features';
import Images from './Images';

export default function Main(props){
    const listing = props.listing
    return(
        <div>

                <div style={{marginTop:20}}>
                    <Images listing={listing} />
                </div>

                <div style={{marginTop:20}}>
                <Typography variant="body1" color="textSecondary" align="justify">
                {listing.description}
                </Typography>
                </div>

                <div style={{marginTop:20}} />
              

                <div style={{marginTop:20}}>
                <Features listing={listing} />
                </div>

               

                

               
        </div>
    )
}

