import { axiosHandler, defaultHandler } from "../requests/global.js";
import { unexpectedError, wait, notify } from "./index";
import {
  FETCH_LISTING,
  FETCH_LISTINGS,
  FETCH_REQUEST,
} from "../constants/listings";

import * as validator from "../validators/request";

export function getListings() {
  const url = "/listing/listings/all";
  return async (dispatch) => {
    dispatch(wait(true));
    try {
      const [res, status] = await defaultHandler(url, {});
      dispatch(fetchListings(res ? res.response.listings : []));
      console.log(res);
    } catch (ex) {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function getRealtorListings(body) {
  const url = "/listing/listings/realtor";
  return async (dispatch) => {
    dispatch(wait(true));
    const [res, status] = await defaultHandler(url, body);
    console.log(res);
    if (status == "ok") {
      if (res.response != null) {
        dispatch(fetchListings(res.response.listings));
      } else {
      }
    } else {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function postRequest(body) {
  const url = "/listing/requests/create";
  return async (dispatch) => {
    dispatch(wait(true));
    try {
      var [status, message] = validator.create(body);
      if (status == "success") {
        const [res, status] = await defaultHandler(url, body);
        dispatch(fetchRequest(res.response.request));
      } else {
        dispatch(wait(false));
        dispatch(notify({ message: message, status: "error", show: true }));
      }
    } catch (ex) {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function getListingByUrl(body) {
  const url = "/app/listings/url";
  return async (dispatch) => {
    dispatch(wait(true));
    try {
      const [res, status] = await defaultHandler(url, body);
      dispatch(fetchListing(res.response.listing));
    } catch (ex) {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}

export function fetchListing(payload) {
  return { type: FETCH_LISTING, payload };
}

export function fetchRequest(payload) {
  return { type: FETCH_REQUEST, payload };
}

export function fetchListings(payload) {
  return { type: FETCH_LISTINGS, payload };
}
