import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";

import VillaIcon from "@mui/icons-material/Villa";
import ReviewsIcon from "@mui/icons-material/Reviews";
import LinkIcon from "@mui/icons-material/Link";
import AnalyticsIcon from "@mui/icons-material/Analytics";

import "react-alice-carousel/lib/alice-carousel.css";

import GridItem from "../../components/mobile/listings/GridItem";
import Profile from "../../components/mobile/realtor/Profile";
import Summary from "../../components/mobile/summary/Main";
import Links from "../../components/mobile/linkings/Main";
import Reviews from "../../components/mobile/reviews/Main";

import { getRealtor } from "../../actions/realtors";
import { getRealtorListings } from "../../actions/listings";
import { getListings } from "../../actions/listings";
const classes = {
  root: {
    marginTop: 10,
  },
};

const styles = {
  media: {
    height: "100%",
    paddingTop: "75%", // 16:9,
    marginTop: "30",
  },
};

export default function Main() {
  const history = useHistory();
  const dispatch = useDispatch();
  const listings = useSelector((state) => state.listing.listings);
  const realtor = useSelector((state) => state.realtor.realtor);
  const [data, setData] = useState(listings ? listings : []);
  const [tab, setTab] = useState(0);

  let { id } = useParams();

  useEffect(() => {
    const body = { url: id };
    dispatch(getRealtor(body));
  }, []);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div>
      <Grid
        style={{ height: "100vh" }}
        justifyContent="center"
        container
        direction="row"
      >
        <Grid
          item
          xs={12}
          lg={10}
          sm={12}
          md={10}
          style={{ marginTop: 0, padding: 10 }}
        >
          <div style={{ marginTop: 10 }} />
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <img
              style={{ width: 150 }}
              src={require("../../images/logo/1x/Asset 1.png")}
            />
          </div>
          <div style={{ marginTop: 30 }} />

          <div style={{ marginTop: 10 }} />
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
              <Profile />
            </Grid>
          </Grid>

          <Tabs
            value={tab}
            onChange={handleChange}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab icon={<AnalyticsIcon />} iconPosition="start" />
            <Tab icon={<VillaIcon />} />

            <Tab icon={<LinkIcon />} />
            <Tab icon={<ReviewsIcon />} />
          </Tabs>

          <div style={{ marginTop: 50 }} />
          {tab == 0 && <Summary />}
          {tab == 1 && <Listings />}
          {tab == 2 && <Links />}
          {tab == 3 && <Reviews />}
        </Grid>
      </Grid>
    </div>
  );
}

function Listings() {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12} sm={12}>
        <div style={{ marginTop: 20 }} />
        <DataGrid />
      </Grid>
    </Grid>
  );
}

function DataGrid(props) {
  const dispatch = useDispatch();
  const listings = useSelector((state) => state.listing.listings);
  const [data, setData] = useState(listings ? listings : []);
  const realtor = useSelector((state) => state.realtor.realtor);

  useEffect(() => {
    const body = { id: realtor.id };
    dispatch(getRealtorListings(body));
  }, [realtor]);

  useEffect(() => {
    setData(listings ? listings : []);
  }, [listings]);

  function handleFilter(searchText, location) {
    if (location == "all") {
      setData(
        listings
          .filter((item) => item.state != location)
          .filter((item) => item.title.includes(searchText))
      );
    } else {
      setData(
        listings
          .filter((item) => item.state == location)
          .filter((item) => item.title.includes(searchText))
      );
    }
  }
  return (
    <>
      <Search update={(search, location) => handleFilter(search, location)} />
      <div style={{ marginTop: 20 }} />
      <Grid container direction="row" spacing={1}>
        {data.map((item) => (
          <Grid item md={4} xs={12} sm={6}>
            <GridItem data={item} />
            <Divider />
          </Grid>
        ))}
      </Grid>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          justifyContent: "center",
          display: "flex",
        }}
      >
        {data.length > 9 && <Pagination count={10} shape="rounded" />}
      </div>
    </>
  );
}

function Statistics() {
  return (
    <Grid container direction="row" justifyContent="space-between" spacing={1}>
      <Grid item md={4}>
        <Typography variant="body1" color="textSecondary">
          Listings
        </Typography>
        <Typography variant="h4" style={{ fontFamily: "UberBold" }}>
          1000
        </Typography>
      </Grid>
      <Grid item md={4}>
        <Typography variant="body1" color="textSecondary">
          New
        </Typography>
        <Typography variant="h4" style={{ fontFamily: "UberBold" }}>
          10
        </Typography>
      </Grid>
      <Grid item md={4}>
        <Typography variant="body1" color="textSecondary">
          Rented
        </Typography>
        <Typography variant="h4" style={{ fontFamily: "UberBold" }}>
          1000
        </Typography>
      </Grid>
    </Grid>
  );
}

function Search(props) {
  const [form, setForm] = useState({ search: "", location: "all" });

  function handleChange(event) {
    const { name, value } = event.target;
    if (name == "search") {
      props.update(value, form.location);
    } else {
      props.update(form.search, value);
    }
    setForm((form) => ({ ...form, [name]: value }));
  }

  return (
    <div>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={8}>
          <TextField
            required
            fullWidth
            id="filled-required"
            label="Search"
            defaultValue=""
            name="search"
            size="small"
            variant="filled"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            id="filled-required"
            label="Location"
            size="small"
            select
            defaultValue="all"
            name="location"
            onChange={handleChange}
            variant="filled"
          >
            <MenuItem value={"all"}> All </MenuItem>
            <MenuItem value={"lagos"}> Lagos </MenuItem>
            <MenuItem value={"abuja"}> Abuja </MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
}
