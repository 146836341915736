import React, { useState, useEffect, createContext, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

//import Login from "./Login";
//import Question from "./Question";
//import Auth from "./Auth";
//import ReviewForm from "./ReviewForm";

import { addReview } from "../../../actions/reviews";
import { findUser, createUser } from "../../../actions/user";

const styles = {
  dialogPaper: {
    minHeight: "50vh",
    maxHeight: "50vh",
  },
};

const ScreenContext = createContext();

export default function Main(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(props.open);
  const user = useSelector((state) => state.user.user);
  const [screen, setScreen] = useState(user.id != null ? "review" : "login");
  const realtor = useSelector((state) => state.realtor.realtor);
  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState({
    reviewer_name: "",
    reviewer_email: "",
    entity: realtor.id,
    rating: "",
    review: "",
  });

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (form.auth != user.auth && screen == "auth") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [form.auth]);

  function handleClose() {
    props.close();
  }

  function handleLogin() {
    if (form.has_account) {
      dispatch(findUser(form, setScreen));
    } else {
      dispatch(createUser(form, setScreen));
    }
  }

  function handleReview() {
    dispatch(addReview(form));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    const newForm = { ...form, [name]: value };
    setForm(newForm);
    //dispatch(updateForm(newForm));
  }

  function handleAuth() {
    if (form.auth == user.auth) {
      setDisabled(false);
      setScreen("review");
    }
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          minHeight: 450,
        },
      }}
      classes={{ paper: styles.dialogPaper }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography variant="h4" style={{ fontFamily: "InterBold" }}>
          Add Review
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ justifyContent: "center", alignItems: "center" }}>
        <TextField
          name="reviewer_name"
          onChange={handleChange}
          fullWidth
          type="text"
          label="Your Name"
          placeholder="Your Name"
          variant="standard"
        />
        <div style={{ marginTop: 10 }} />
        <TextField
          name="reviewer_email"
          onChange={handleChange}
          fullWidth
          type="email"
          label="Email Address"
          placeholder="Email Address"
          variant="standard"
        />
        <div style={{ marginTop: 10 }} />
        <FormControl>
          <FormLabel id="value">
            <Typography variant="body1">Rating</Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="rating"
            row
            onChange={handleChange}
            name="rating"
          >
            <FormControlLabel
              value="excellent"
              control={<Radio />}
              label="Excellent"
            />
            <FormControlLabel value="good" control={<Radio />} label="Good" />
            <FormControlLabel
              value="average"
              control={<Radio />}
              label="Average"
            />
            <FormControlLabel value="fair" control={<Radio />} label="Fair" />
            <FormControlLabel value="poor" control={<Radio />} label="Poor" />
          </RadioGroup>
        </FormControl>

        <div style={{ marginTop: 20 }} />
        <TextField
          multiline
          variant="standard"
          rows={5}
          name="review"
          onChange={handleChange}
          label="Write your review"
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          <Typography variant="body1" fontFamily="SpaceBold">
            Cancel
          </Typography>
        </Button>
        <Button disabled={disabled} onClick={handleReview}>
          <Typography variant="body1" style={{ fontFamily: "SpaceBold" }}>
            Continue
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
