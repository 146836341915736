import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import VerifiedIcon from "@mui/icons-material/Verified";
import ConnectIcon from "@mui/icons-material/ConnectWithoutContactOutlined";

import ProfileAvatar from "./Avatar";
import RequestModal from "./RequestForm";
import { postRequest } from "../../../actions/listings";

export default function Main() {
  const dispatch = useDispatch();
  const realtor = useSelector((state) => state.realtor.realtor);
  const [modal, setModal] = useState(false);

  function handleClick() {
    setModal(true);
  }

  return (
    <div>
      <RequestModal open={modal} close={() => setModal(false)} />
      {realtor && (
        <Box sx={{ padding: 2, borderRadius: 5, width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ProfileAvatar
              uri={realtor.avatar}
              style={{ width: 60, height: 60 }}
            />
            <div style={{ marginTop: 10 }} />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h6" style={{ fontFamily: "InterBold" }}>
                  {realtor.name}{" "}
                </Typography>
              </Grid>
              <Grid item>
                <VerifiedIcon />
              </Grid>
            </Grid>

            <Typography variant="body1" color="textSecondary">
              {realtor.company_name}
            </Typography>
          </div>

          <div style={{ marginTop: 20 }} />

          <Grid container direction="row">
            <Grid item md={4}>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <MailIcon />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary">{realtor.email}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <CallIcon />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary">{realtor.phone}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={4}>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <TwitterIcon />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary">
                    {realtor.twitter}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={4}>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <InstagramIcon />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary">
                    {realtor.instagram}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ marginTop: 20 }} />
          <Divider />
          <div style={{ marginTop: 20 }}>
            <Typography variant="body1" color="textSecondary" align="justify">
              {realtor.about}
            </Typography>
          </div>

          <div style={{ marginTop: 20 }} />

          <Button onClick={handleClick} startIcon={<ConnectIcon />}>
            <Typography variant="body1" fontFamily="SpaceBold">
              Contact Me!
            </Typography>
          </Button>
        </Box>
      )}
    </div>
  );
}
