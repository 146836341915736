import React from 'react';

import {ResponsiveContainer, BarChart, Bar, Legend, Pie, PieChart,  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine} from 'recharts';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const data = [
    {
      "name": "January",
      "views": 4000,
    },
    {
      "name": "February",
      "views": 3000,
    },
    {
      "name": "March",
      "views": 2000,
    },
    {
      "name": "April",
      "views": 2780,
    },
    {
      "name": "May",
      "views": 1890,
    },
    {
      "name": "June",
      "views": 2390,
    },
    {
      "name": "July",
      "views": 3490,
    }
  ]

  const data_bar = [
    {
      "name": "Jan",
      "short": 4000,
      "long": 2400
    },
    {
      "name": "Feb",
      "short": 3000,
      "long": 1398
    },
    {
      "name": "Mar",
      "short": 2000,
      "long": 9800
    },
    {
      "name": "Apr",
      "short": 2780,
      "long": 3908
    },
    {
      "name": "Jun",
      "short": 1890,
      "long": 4800
    },
    {
      "name": "Jul",
      "short": 2390,
      "long": 3800
    },
    {
      "name": "Aug",
      "short": 3490,
      "long": 4300
    }
  ]


const data01 = [
  {
    "name": "Group A",
    "value": 400
  },
  {
    "name": "Group B",
    "value": 300
  },
  {
    "name": "Group C",
    "value": 300
  },
  {
    "name": "Group D",
    "value": 200
  },
  {
    "name": "Group E",
    "value": 278
  },
  {
    "name": "Group F",
    "value": 189
  }
];
const data02 = [
  {
    "name": "Group A",
    "value": 2400
  },
  {
    "name": "Group B",
    "value": 4567
  },
  {
    "name": "Group C",
    "value": 1398
  },
  {
    "name": "Group D",
    "value": 9800
  },
  {
    "name": "Group E",
    "value": 3908
  },
  {
    "name": "Group F",
    "value": 4800
  }
];

export default function Main(){
    return(
        <div style={{width:"100%"}}>

            <Grid container direction="row">
                <Grid item md={6}>
                <Typography variant="body1" style={{fontFamily:"InterBold"}} color="textSecondary">Views by Location </Typography>
                <PieChart width={730} height={250}>
                <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#000000" />
                <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#3F6634" label />
                </PieChart>
                </Grid>
                <Grid item md={6}>
                <Typography variant="body1" style={{fontFamily:"InterBold"}} color="textSecondary">Listings by Location </Typography>
                <PieChart width={730} height={250}>
                <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#000000" />
                <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#3F6634" label />
                </PieChart>
                </Grid>
            </Grid>

            <div style={{height:300, width:"100%"}}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> LISTINGS </Typography>
            <div style={{marginTop:10}} />
                <ResponsiveContainer width={"100%"} height="80%">
                    <BarChart width={"100%"} height={250} data={data_bar}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="short" fill="#000000" radius={[10, 10, 10, 10]} />
                    <Bar dataKey="long" fill="#3F6634" radius={[10, 10, 10, 10]} />
                    </BarChart>
                    </ResponsiveContainer>
            </div>

            <div style={{height:300, width:"100%"}}>
            <Typography variant="body1" color="textSecondary" style={{fontFamily:"InterBold"}}> VIEWS </Typography>
            <div style={{marginTop:10}} />
            <ResponsiveContainer width={"100%"} height="80%">
            <AreaChart data={data}
            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="views" stroke="#000000" fill="#000000" />
            </AreaChart>
            </ResponsiveContainer>
            </div>

            
            
        </div>
    )
}