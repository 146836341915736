import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { fetchRealtor } from "../../../actions/realtors";

import ImageAvatar from "./Avatar";

export default function Main(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const realtor = props.data;

  function handleClick() {
    dispatch(fetchRealtor(props.data));
    history.push("/" + props.data.url);
  }

  return (
    <Card elevation={0} button sx={{ padding: 0 }}>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ImageAvatar uri={realtor.avatar} />
            <div style={{ marginTop: 10 }} />
            <Typography variant="body1" style={{ fontFamily: "InterBold" }}>
              {realtor.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {realtor.company_name}
            </Typography>
          </div>

          <div style={{ marginTop: 20 }}>
            <Typography
              variant="body1"
              noWrap
              color="textSecondary"
              align="justify"
            >
              {realtor.about}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
