import { axiosHandler, defaultHandler } from "../requests/global.js";
import { unexpectedError, wait, notify } from "./index";

import * as validator from "../validators/request.js";

export function addRequest(body) {
  const url = "/page/requests/add";
  return async (dispatch) => {
    dispatch(wait(true));
    try {
      const [res, status] = await defaultHandler(url, body);
      console.log(res);
      dispatch(notify({ message: res.message, status: res.status }));
      //dispatch(fetchReviews(res ? res.response.reviews : []));
    } catch (ex) {
      dispatch(unexpectedError());
    }
    dispatch(wait(false));
  };
}
