import {FETCH_REVIEWS, FETCH_REVIEW, UPDATE_FORM} from  "../constants/reviews";


const initialState = {
  review:{
  },
  reviews:[],
  form:{

  }
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_REVIEW:
      return Object.assign({}, state, {
         review: action.payload
       });
    case FETCH_REVIEWS:
      return Object.assign({}, state, {
         reviews: action.payload
      });
    case UPDATE_FORM:
        return Object.assign({}, state, {
          form: action.payload
        });
  }
  return state;
}

export default reducer;
