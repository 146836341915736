import React from "react";

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  Pie,
  PieChart,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from "recharts";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import PieCharts from "./PieCharts";
import Summary from "./Summary";
import ListingValue from "./ListingValue";
import ListingChart from "./ListingChart";

export default function Main() {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginTop: 40 }} />
      <Summary />

      <div style={{ marginTop: 40 }} />
      <PieCharts />

      <div style={{ marginTop: 40 }} />

      <ListingValue />
    </div>
  );
}
