export function ratingDistribution(data) {
  const results = [];
  const ratings = ["excellent", "good", "average", "fair", "poor"];

  ratings.forEach((rating) => {
    const filtered = data.filter((item) => item.rating == rating);
    const entry = { name: rating, value: filtered.length };
    results.push(entry);
  });

  return results;
}

export function averageRating(data) {
  const ratings = ["excellent", "good", "average", "fair", "poor"];
  var sum = 0;
  var total = 0;
  console.log(data);
  data.forEach((item) => {
    sum = sum + (ratings.reverse().indexOf(item.name) + 1) * item.value;
    total = total + item.value;
    console.log(item.value);
  });

  return data.length > 0 ? parseFloat(sum / total).toFixed(2) : 0;
}
export function genderDistribution(data) {
  const pieCancerous = [];
  const pieNonCancerous = [];

  const genderArr = ["Male", "Female"];

  genderArr.forEach((gender) => {
    const filteredArray = data.filter(
      (item) => item.diagnosis === "Cancerous" && item.gender === gender
    );
    const entry = { name: gender, value: filteredArray.length };
    pieCancerous.push(entry);
  });

  genderArr.forEach((gender) => {
    const filteredArray = data.filter(
      (item) => item.diagnosis !== "Cancerous" && item.gender === gender
    );
    const entry = { name: gender, value: filteredArray.length };
    pieNonCancerous.push(entry);
  });

  //const genderSet = new Set(data.map(item => item.gender));
  //const ge
  console.log(pieCancerous);
  return [pieCancerous, pieNonCancerous];
}
