import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  Pie,
  Cell,
  PieChart,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from "recharts";

import RatingChart from "../reviews/Chart";
import { averageRating, ratingDistribution } from "../../../functions/chart";
import { listingCategory, locationCategory } from "../../../functions/listings";
const data = [
  {
    name: "January",
    views: 4000,
  },
  {
    name: "February",
    views: 3000,
  },
  {
    name: "March",
    views: 2000,
  },
  {
    name: "April",
    views: 2780,
  },
  {
    name: "May",
    views: 1890,
  },
  {
    name: "June",
    views: 2390,
  },
  {
    name: "July",
    views: 3490,
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function Main() {
  const reviews = useSelector((state) => state.review.reviews);
  const listings = useSelector((state) => state.listing.listings);
  const listingCategoryData = listingCategory(listings);
  const locationCategoryData = locationCategory(listings);

  console.log(locationCategoryData);

  return (
    <Grid container direction="row">
      <Grid item md={6}>
        <div style={{ height: 250, width: "100%" }}>
          <ResponsiveContainer width={"100%"} height="80%">
            <PieChart width={730} height={250}>
              <Pie
                data={listingCategoryData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {listingCategoryData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </ResponsiveContainer>
          <Typography
            align="center"
            variant="body1"
            style={{ fontFamily: "InterBold" }}
            color="textSecondary"
          >
            Listings by Category{" "}
          </Typography>
        </div>
      </Grid>
      <Grid item md={6}>
        <div style={{ height: 250, width: "100%" }}>
          <ResponsiveContainer width={"100%"} height="80%">
            <PieChart width={730} height={250}>
              <Pie
                data={locationCategoryData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {locationCategoryData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </ResponsiveContainer>
          <Typography
            align="center"
            variant="body1"
            style={{ fontFamily: "InterBold" }}
            color="textSecondary"
          >
            Listings by Location{" "}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
