import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {useParams} from 'react-router-dom';


import ChecklistIcon from '@mui/icons-material/Checklist';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ReviewsIcon from '@mui/icons-material/Reviews';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

import getSymbolFromCurrency from 'currency-symbol-map';

import { getListingByUrl } from '../../actions/listings';
import { getRealtors} from '../../actions/realtors';
import Features from '../../components/web/listing/About';
import Review from '../../components/web/listing/Review';
import Analytics from '../../components/web/listing/Analytics';
import Video from '../../components/web/listing/Video';
import Images from '../../components/listings/Images';
import Realtor from '../../components/listings/Realtor';


export default function Main(){
    const dispatch = useDispatch();
    const history = useHistory();
    const listing = useSelector(state => state.listing.listing);
    const realtors = useSelector(state => state.realtor.realtors);
    const [tab, setTab] = useState(0);

    const [realtor, setRealtor] = useState(realtors.length > 0 ? 
        realtors.filter((item) => item.id == listing.realtor)[0] : {});
    let { url } = useParams();

    useEffect(() => {
        setRealtor(realtors.length > 0 ? realtors.filter((item) => item.id == listing.realtor)[0] : {});
    }, [realtors])

    useEffect(() => {
        const body = {url: url}
        dispatch(getListingByUrl(body, history));
        dispatch(getRealtors());
    }, []);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
  

    return(
        <div style={{padding:10}}>

                <Grid container direction="row" justifyContent={"center"} spacing={2}>
                <Grid item md={6}>
                <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <img style={{width:150}}  src={require("../../images/logo/1x/Asset 1.png")}/>
                </div>
                </Grid>
                </Grid>

                
                <div style={{marginTop:20}}/>
                <Grid container direction="row" justifyContent={"center"} spacing={2}>
                <Grid item md={6}>
                <IconButton onClick={() => history.goBack()}>
                    <ChevronLeftIcon color="primary" fontSize='large'/>
                </IconButton>
               

                <div style={{marginTop:20}} />
                
                <Grid container direction="row" justifyContent={"center"}>
                    <Grid item md={12}>
                    <Typography align="center" variant="h4" style={{fontFamily:"InterBold"}}>{listing.title}</Typography>

                    <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                        <Grid item>
                        <PlaceOutlinedIcon/>
                        </Grid>
                        <Grid item>
                        <Typography  align="center"  variant="h6" color="textSecondary">{listing.address}</Typography>
                        </Grid>
                    </Grid>
                 
                    <Typography  align="center"  color="textSecondary" variant="h6" >
                       {listing.price} per annum
                    </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>

                <div style={{marginTop:20}} />
                <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item>
                    <Chip label={<Typography variant="body1">Family</Typography>} />
                    </Grid>
                    <Grid item>
                    <Chip label={<Typography variant="body1">Shortlet</Typography>} />
                    </Grid>
                    <Grid item>
                    <Chip label={<Typography variant="body1">Luxury</Typography>} />
                    </Grid>
                    

                </Grid>
                <div style={{marginTop:20}} />

                <Tabs
                    value={tab}
                    onChange={handleChange}
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab icon={<ChecklistIcon />} iconPosition="start" label={<Typography variant="body1" style={{fontFamily:"InterBold"}}>Features</Typography>}  />
                    <Tab icon={<PlayCircleIcon />} iconPosition="start" label={<Typography variant="body1" style={{fontFamily:"InterBold"}}>Reels</Typography>} />
                    <Tab icon={<ReviewsIcon />} iconPosition="start" label={<Typography variant="body1" style={{fontFamily:"InterBold"}}>Reviews</Typography>}  />  
                    <Tab icon={<AnalyticsIcon />} iconPosition="start" label={<Typography variant="body1" style={{fontFamily:"InterBold"}}>Analytics</Typography>} />
                </Tabs>
                
                <div style={{marginTop:20}} />

                {tab == 0 && <Features listing={listing} />}
                {tab == 1 && <Video listing={listing} />}
                {tab == 2 && <Review listing={listing} />}
                {tab == 3 && <Analytics listing={listing} />}
               

                    
               

                
                   


                </Grid>

                </Grid>
           
        </div>
    )
}