import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import PieCharts from "./PieCharts";
import Summary from "./Summary";
import ListingValue from "./ListingValue";
import ListingChart from "./ListingChart";

import { getRealtorListings } from "../../../actions/listings";
import { getRealtorReviews } from "../../../actions/reviews";

export default function Main() {
  const dispatch = useDispatch();
  const realtor = useSelector((state) => state.realtor.realtor);

  useEffect(() => {
    const body = { id: realtor.id };
    dispatch(getRealtorListings(body));
    dispatch(getRealtorReviews(body));
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginTop: 40 }} />
      <Summary />

      <div style={{ marginTop: 40 }} />
      <PieCharts />

      <div style={{ marginTop: 40 }} />

      <Grid container direction="row">
        <Grid item md={6}>
          <ListingValue />
        </Grid>
        <Grid item md={6}>
          <ListingChart />
        </Grid>
      </Grid>
    </div>
  );
}
