import React, { useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  Pie,
  Cell,
  PieChart,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from "recharts";
import {
  dailyListing,
  monthlyListing,
  yearlyListing,
} from "../../../functions/listings";

const data_bar = [
  {
    name: "Jan",
    short: 4000,
    long: 2400,
  },
  {
    name: "Feb",
    short: 3000,
    long: 1398,
  },
  {
    name: "Mar",
    short: 2000,
    long: 9800,
  },
  {
    name: "Apr",
    short: 2780,
    long: 3908,
  },
  {
    name: "Jun",
    short: 1890,
    long: 4800,
  },
  {
    name: "Jul",
    short: 2390,
    long: 3800,
  },
  {
    name: "Aug",
    short: 3490,
    long: 4300,
  },
];

export default function Main() {
  const listings = useSelector((state) => state.listing.listings);
  const dailyListings = dailyListing(listings);
  const monthlyListings = monthlyListing(listings);
  const yearlyListings = yearlyListing(listings);
  const [period, setPeriod] = useState("daily");

  function handlePeriodChange(value) {
    setPeriod(value);
  }

  function handlePeriodChange(value) {
    setPeriod(value);
  }

  function handleClick() {}

  return (
    <div style={{ height: 300, width: "100%" }}>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ fontFamily: "InterBold" }}
      >
        {" "}
        LISTINGS{" "}
      </Typography>
      <div style={{ marginTop: 10 }} />
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <Chip
            clickable
            color={period == "daily" ? "success" : "default"}
            onClick={() => handlePeriodChange("daily")}
            variant={period == "daily" ? "filled" : "outlined"}
            label={<Typography variant="body2">Daily </Typography>}
          />
        </Grid>
        <Grid item>
          <Chip
            clickable
            color={period == "monthly" ? "success" : "default"}
            onClick={() => handlePeriodChange("monthly")}
            variant={period == "monthly" ? "filled" : "outlined"}
            label={<Typography variant="body2">Monthly </Typography>}
          />
        </Grid>
        <Grid item>
          <Chip
            clickable
            color={period == "yearly" ? "success" : "default"}
            onClick={() => handlePeriodChange("yearly")}
            variant={period == "yearly" ? "filled" : "outlined"}
            label={<Typography variant="body2">Yearly </Typography>}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: 10 }} />
      <ResponsiveContainer width={"100%"} height="80%">
        <BarChart
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
          data={
            period == "daily"
              ? dailyListings
              : period == "monthly"
              ? monthlyListings
              : yearlyListings
          }
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="rental" fill="#000000" radius={[10, 10, 0, 0]} />
          <Bar dataKey="sale" fill="#00C49F" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
